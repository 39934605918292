import React, { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import {
  Button,
  createStyles,
  Grid,
  StyleRules,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PersonIcon from '@material-ui/icons/Person';

import {
  companyGetProjectBidById,
  companyUpdateProjectBidStatus,
} from '../../actions/projectBidAction';
import {
  PassCompanyBiddingProjectDialog,
  RefuseCompanyBiddingProjectDialog,
} from '../../components/Dialog';
import { calculateAge } from '../../utils/calculateAge';
import { formatDate } from '../../utils/dateFormat';
import { RecruitmentStatus } from './CompetitiveBiddingListView';
import SendOffer from './SendOfferView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    content: {
      [theme.breakpoints.up('md')]: {
        width: '50rem',
        padding: '1rem',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '0.5rem',
      },
    },
    prosenInfo: {
      display: 'flex',
    },
    border: {
      padding: '1rem',
      [theme.breakpoints.up('sm')]: {
        padding: '0.5rem',
      },
      '& p': {
        margin: '0.5rem 0',
      },
    },
    buttonMargin: {
      margin: '1rem',
      display: 'line-block',
    },
    marginTopUP: {
      fontSize: '1rem',
      borderBottom: '1px solid #d9d9d9',
      margin: '1rem',
      [theme.breakpoints.up('sm')]: {
        margin: '0.5rem',
      },
      paddingBottom: '1rem',
      '& div': {
        margin: '0.5rem',
      },
      spaced: {
        margin: '0 2rem',
      },
    },
    avatarBox: { display: 'flex', alignItems: 'center' },
    littleTittle: { fontSize: '1.4rem', fontWeight: 'bold', display: 'flex', alignItems: 'center' },
    avatar: {
      borderRadius: '50%',
      width: '3.5rem',
      height: '3.5rem',
      margin: '0',
    },
    fontBold: { fontWeight: 'bold' },
    contentPadding: { padding: '1rem 0 0 4.2rem' },
    inlineBox: {
      paddingLeft: '4rem',
    },
    spanMargin: {
      margin: '0 1rem',
    },
    spanColor: {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  });

interface workScenarioType {
  remote: boolean | string;
  onsite: boolean | string;
}
const competitiveBiddingDetailsView = ({
  classes,
  onClose,
  statusValue,
  subOffer = false,
  bid,
  fetchBidData,
}: Props): ReactElement => {
  const [isPassBiddingOpen, setIsPassBiddingOpen] = useState(false);
  const [isRefuseBiddingOpen, setIsRefuseBiddingOpen] = useState(false);
  const dispatch = useDispatch();

  const [requestData, setRequestData] = useState<Bidding_info>();
  const [workScenario, setWorkScenario] = useState<workScenarioType>();
  const fetchData = async () => {
    const data = await dispatch(companyGetProjectBidById(bid));
    setRequestData(data);
    if (data.offer) {
      setWorkScenario(JSON.parse(data.offer.workScenario));
    }
  };

  const firstPass = async () => {
    await dispatch(companyUpdateProjectBidStatus(bid, 'IN_NEGOTIATION'));
    setIsPassBiddingOpen(false);
    fetchBidData ? fetchBidData() : '';
    onClose ? onClose() : '';
  };

  const refusePass = async () => {
    await dispatch(companyUpdateProjectBidStatus(bid, 'NOT_SUITABLE'));
    setIsRefuseBiddingOpen(false);
    fetchBidData ? fetchBidData() : '';
    onClose ? onClose() : '';
  };

  const formatResume = (el: string) => {
    if (!el) return null;
    const name = `${el.split(';')[0].split(':')[1]}`;
    return {
      name,
      url: `name:${name};${el.split(';')[1]},${el.split(';')[2]}`,
    };
  };

  //将完整的base64转换为blob
  const dataURLtoBlob = (dataUrl: string) => {
    const arr = dataUrl.split(',') || [];
    const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleDownload = (resume?: string) => {
    if (!resume) return;
    if (!formatResume(resume)) return;
    const { name, url: resumeUrl } = formatResume(resume);
    const url = `${resumeUrl.split(';')?.[1]},${resumeUrl.split(';')?.[2]}` || '';
    const blob = dataURLtoBlob(url);
    const a = document.createElement('a');
    a.download = name;
    a.style.display = 'none';
    a.href = URL.createObjectURL(blob);
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  useEffect(() => {
    fetchData();
    const anchorElement = document.getElementById('offer');
    if (anchorElement) {
      // 使用scrollIntoView方法滚动到锚点
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      <Helmet title="顾问申请详情"></Helmet>
      <Grid xs={12} className={classes.content} container>
        {requestData?.offer && (
          <Grid item xs={12} className={classes.marginTopUP}>
            <div className={classes.littleTittle}>
              <LocalOfferIcon />
              合同信息
            </div>
            <div className={classes.contentPadding}>
              <div>
                <span className={classes.fontBold}>项目名称：</span>
                {requestData?.offer.offerName}
              </div>
              <div>
                <span className={classes.fontBold}>项目预算：</span>
                {requestData?.offer.projectRemuneration}
              </div>
              <div>
                <span className={classes.fontBold}>项目内容：</span>
                {requestData?.offer.offerContent}
              </div>
              <div>
                <span className={classes.fontBold}>工作量：</span>
                {requestData?.offer.workload}
              </div>
              <div>
                <span className={classes.fontBold}>工作地址：</span>
                {requestData?.offer.workLocationAddress}
              </div>
              <div>
                <span className={classes.fontBold}>联系方式：</span>
                {requestData?.offer.contactInfo}
              </div>
              <div>
                <span className={classes.fontBold}>项目时间：</span>
                {formatDate(new Date(`${requestData?.offer.projectStartTime}`), 'yyyy-MM-dd')}-
                {formatDate(new Date(`${requestData?.offer.projectEndTime}`), 'yyyy-MM-dd')}
              </div>
              <div>
                <span className={classes.fontBold}>工作方式：</span>
                {workScenario?.remote && '远程'}
                <span className={classes.spanColor}></span>
                {workScenario?.onsite && '线下'}
              </div>
              <div>
                <span className={classes.fontBold}>顾问留言：</span>
                {requestData.offer.consultantComment}
              </div>
              <div>
                <span className={classes.fontBold}>企业留言：</span>
                {requestData.offer.companyComment}
              </div>
            </div>
          </Grid>
        )}
        <Grid item xs={12} className={classes.marginTopUP}>
          <div className={classes.littleTittle}>
            <AssignmentIcon />
            项目简介
          </div>
          <div className={classes.contentPadding}>
            <div>
              <span className={classes.fontBold}>项目名称：</span>
              {requestData?.request?.projectName}
            </div>
            {requestData?.request?.projectContent ? (
              <div>
                <span className={classes.fontBold}> 简介：</span>
                {requestData?.request?.projectContent}
              </div>
            ) : null}
            <div>
              <span className={classes.fontBold}> 项目时间：</span>
              {requestData?.request?.projectStartAsap
                ? requestData?.request.projectStartAsap
                : formatDate(new Date(`${requestData?.request?.projectStartTime}`), 'yyyy-MM-dd')}
              <span className={classes.spanMargin}>-</span>
              {formatDate(new Date(`${requestData?.request?.projectEndTime}`), 'yyyy-MM-dd')}
              {requestData?.request?.projectLongTerm && '长期项目'}
            </div>

            <div>
              <span className={classes.fontBold}> 结算预算：</span>
              {requestData?.request?.remunerationInfo?.monthlySalaryMin && '月度预算'}
              {requestData?.request?.remunerationInfo?.singleSalaryMin && '单次预算'}
              {requestData?.request?.remunerationInfo?.otherSalaryDescription && '其他'}
              {requestData?.request?.remunerationInfo?.monthlySalaryMin && (
                <div>
                  <div className={classes.inlineBox}>
                    预计最低预算：{requestData?.request?.remunerationInfo.monthlySalaryMin}
                    <span className={classes.spaced}></span> 预计最高预算：
                    {requestData?.request?.remunerationInfo.monthlySalaryMax}
                  </div>
                  {requestData?.request?.remunerationInfo?.monthlySalaryDescription && (
                    <div className={classes.inlineBox}>
                      结算周期：{requestData?.request?.remunerationInfo?.monthlySalaryDescription}
                    </div>
                  )}
                </div>
              )}
              {requestData?.request?.remunerationInfo?.singleSalaryMin && (
                <div>
                  <div className={classes.inlineBox}>
                    预计最低预算：{requestData?.request?.remunerationInfo.singleSalaryMin}
                    <span className={classes.spaced}></span> 预计最高预算：
                    {requestData?.request?.remunerationInfo.singleSalaryMax}
                  </div>
                  {requestData?.request?.remunerationInfo?.singleSalaryDescription && (
                    <div className={classes.inlineBox}>
                      结算周期：{requestData?.request?.remunerationInfo?.singleSalaryDescription}
                    </div>
                  )}
                </div>
              )}
              {requestData?.request?.remunerationInfo?.otherSalaryDescription && (
                <div>
                  <div className={classes.inlineBox}>
                    预算描述：{requestData?.request?.remunerationInfo.otherSalaryDescription}
                  </div>
                  {requestData?.request?.remunerationInfo?.otherSalaryCycle && (
                    <div className={classes.inlineBox}>
                      结算周期：{requestData?.request?.remunerationInfo?.otherSalaryCycle}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.marginTopUP}>
          <div className={classes.littleTittle}>
            <ListAltIcon />
            申请方案
          </div>

          <div className={classes.contentPadding}>
            {requestData?.bidSalary ? (
              <div>
                <span className={classes.fontBold}>申请报价：</span>
                {requestData.bidSalary}
              </div>
            ) : null}

            {requestData?.consultantBidAdvantages ? (
              <div>
                <span className={classes.fontBold}>方案优势：</span>
                {requestData.consultantBidAdvantages}
              </div>
            ) : null}
            {requestData?.consultantBidAdvantages ? (
              <div>
                <span className={classes.fontBold}>申请时间：</span>
                {requestData.startAsap
                  ? requestData.startAsap
                  : formatDate(new Date(`${requestData?.startDate}`), 'yyyy-MM-dd')}
              </div>
            ) : null}
            {/* startDate
      startAsap
      lastUpdateTime
      endDate */}
            {requestData?.attachments && requestData?.attachments?.length > 0 ? (
              <div>
                <span className={classes.fontBold}>附件图片：</span>
                {requestData.attachments.map((item, index) => {
                  return <img src={item.file} alt="附件" key={index}></img>;
                })}
              </div>
            ) : null}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.marginTopUP}>
          <div className={classes.littleTittle}>
            <PersonIcon />
            申请顾问
          </div>
          <div className={classes.contentPadding}>
            <div className={classes.avatarBox}>
              <img
                src={requestData?.consultant?.verification?.basicInfo?.avatar}
                alt="User Avatar"
                className={classes.avatar}
              ></img>
              {requestData?.consultant?.verification?.basicInfo?.firstName ? (
                <span className={classes.spanMargin}>
                  {requestData?.consultant?.verification?.basicInfo?.firstName}
                  {requestData?.consultant?.verification?.basicInfo?.lastName}
                </span>
              ) : null}
              <span className={classes.spanColor}>|</span>
              {requestData?.consultant?.verification?.basicInfo?.birthday ? (
                <span className={classes.spanMargin}>
                  {calculateAge(requestData.consultant.verification.basicInfo.birthday)}
                </span>
              ) : null}
              <span className={classes.spanColor}>|</span>
              {requestData?.consultant?.verification?.basicInfo?.gender ? (
                <span className={classes.spanMargin}>
                  {requestData.consultant.verification.basicInfo.gender == 'female' ? '女' : '男'}
                </span>
              ) : null}
            </div>
            <div>
              {requestData?.consultant?.verification?.otherInfo?.background ? (
                <div>
                  <span className={classes.fontBold}>个人背景：</span>
                  {requestData?.consultant?.verification?.otherInfo?.background}
                </div>
              ) : (
                ''
              )}
              {requestData?.consultant?.verification?.otherInfo?.description ? (
                <div>
                  <span className={classes.fontBold}>个人描述：</span>
                  {requestData?.consultant?.verification?.otherInfo?.description}
                </div>
              ) : (
                ''
              )}
              {requestData?.consultant?.verification?.otherInfo?.strength ? (
                <div>
                  <span className={classes.fontBold}>个人能力：</span>
                  {requestData?.consultant?.verification?.otherInfo?.strength}
                </div>
              ) : (
                ''
              )}
              {requestData?.consultant?.verification?.otherInfo?.resume ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <span className={classes.fontBold}>个人简历：</span>
                  <span>
                    {formatResume(requestData?.consultant?.verification?.otherInfo?.resume)?.name}
                  </span>
                  <GetAppIcon
                    style={{
                      cursor: 'pointer',
                      marginLeft: '0.5rem',
                    }}
                    onClick={() =>
                      handleDownload(requestData?.consultant?.verification?.otherInfo?.resume)
                    }
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </Grid>

        {subOffer && (
          <div id="offer">
            <SendOffer onClose={onClose} bid={bid} request={requestData?.request}></SendOffer>
          </div>
        )}
        <Grid item xs={12} container justifyContent="center">
          {statusValue === RecruitmentStatus.InitialScreening && (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="small"
              className={classes.buttonMargin}
              onClick={() => {
                setIsPassBiddingOpen(true);
              }}
            >
              <CheckIcon fontSize="small" /> 通过初筛
            </Button>
          )}

          {statusValue !== RecruitmentStatus.NotSuitable &&
            statusValue !== RecruitmentStatus.Interview && (
              <Button
                size="small"
                type="button"
                variant="contained"
                color="primary"
                className={classes.buttonMargin}
                onClick={() => {
                  setIsRefuseBiddingOpen(true);
                }}
              >
                <NotInterestedIcon fontSize="small" /> 不合适
              </Button>
            )}
        </Grid>
      </Grid>
      <PassCompanyBiddingProjectDialog
        isOpen={isPassBiddingOpen}
        handleDialogClose={() => {
          setIsPassBiddingOpen(false);
        }}
        handleEdit={firstPass}
      ></PassCompanyBiddingProjectDialog>
      <RefuseCompanyBiddingProjectDialog
        isOpen={isRefuseBiddingOpen}
        handleDialogClose={() => {
          setIsRefuseBiddingOpen(false);
          onClose ? onClose() : '';
        }}
        handleEdit={refusePass}
      ></RefuseCompanyBiddingProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  statusValue?: RecruitmentStatus;
  subOffer?: boolean;
  offerOpen?: Function;
  bid?: number;
  bidItem?: Bidding_info;
  fetchBidData?: Function;
}

export default withStyles(styles)(competitiveBiddingDetailsView);
