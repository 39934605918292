import React from 'react';

import Layout from '../../layout/CompanyLayout';
import CompetitiveBiddingListView from '../../views/company/CompetitiveBiddingListView';
const ProjectList = (): React.ReactElement => {
  return (
    <Layout>
      <CompetitiveBiddingListView></CompetitiveBiddingListView>
    </Layout>
  );
};

export default ProjectList;
