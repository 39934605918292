/**
 * 初始化企业offer信息
 *
 * @export
 * @return {*}  {requestOfferInfo}
 */
export const createCompanyRequestOfferInfo = (): requestOfferInfo => ({
  //   id: number,
  // 状态
  status: 'NONE',
  // offer名
  offerName: '',
  // 内容
  offerContent: '',
  // 工作方式
  workScenario: {
    remote: false,
    onsite: false,
  },
  // 工作量
  workload: '',
  // 工作地址
  workLocationAddress: '',
  // 结束时间
  projectEndTime: null,
  // 开始时间
  projectStartTime: null,
  // 酬劳
  projectRemuneration: '',
  // 联系方式
  contactInfo: '',
  // 企业评价
  companyComment: '1',
  // 顾问评价
  consultantComment: '1',
});
// workLocationCity?: string | undefined;
// workLocationProvince?: string | undefined;
// workLocationDistrict?: string | undefined;
