import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import debug from '../utils/debug';
import { mutate, query } from '../utils/graphql';
import {
  COMPANY_ADD_PROJECT_OFFER,
  COMPANY_GET_ALL_PROJECT_OFFER,
  COMPANY_GET_PROJECT_OFFER_BY_ID,
} from '../utils/graphqlStrings';

// 发送offer
export const companySendProjectOffer: Function = (offer: requestOfferInfo) => async (
  dispatch: Function
): Promise<requestOfferInfo> => {
  debug('companyAddProjectOffer');
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await mutate(COMPANY_ADD_PROJECT_OFFER, { offer });
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: 'offer发送失败' }));
    return {} as requestOfferInfo;
  }

  if (data && data?.companyAddProjectOffer) {
    const companyAddProjectOffer = data.companyAddProjectOffer;
    debug('Add companyAddProjectOffer successfully');
    debug(companyAddProjectOffer);
    dispatch(loadingEndAction());
    // dispatch(companyRequestKnownAction(companyAddProjectOffer));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: 'offer发送成功',
      })
    );
    return companyAddProjectOffer as requestOfferInfo;
  }
  return {} as requestOfferInfo;
};
// 企业查询所有offer
export const companyGetAllProjectOffer: Function = () => async (
  dispatch: Function
): Promise<requestOfferInfo[]> => {
  debug('companyGetAllProjectOffer');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(COMPANY_GET_ALL_PROJECT_OFFER);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取企业合同信息失败' }));
    return [] as requestOfferInfo[];
  }

  if (data && data?.companyGetAllProjectOffer) {
    const companyGetAllProjectOffer = data.companyGetAllProjectOffer;
    debug('Get companyGetAllProjectOffer successfully');
    debug(companyGetAllProjectOffer);
    dispatch(loadingEndAction());

    return companyGetAllProjectOffer as [];
  }

  return [] as requestOfferInfo[];
};

// 查看offer信息
export const companyGetProjectOfferById: Function = (offerId: number) => async (
  dispatch: Function
): Promise<requestOfferInfo> => {
  debug('companyGetProjectOfferById');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(COMPANY_GET_PROJECT_OFFER_BY_ID, { offerId });
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '获取合同信息失败' }));
    return {} as requestOfferInfo;
  }

  if (data && data?.companyGetProjectOfferById) {
    const companyGetProjectOfferById = data.companyGetProjectOfferById;
    debug('Get companyGetProjectOfferById successfully');
    debug(companyGetProjectOfferById);
    dispatch(loadingEndAction());

    return companyGetProjectOfferById as requestOfferInfo;
  }
  return {} as requestOfferInfo;
};
