import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles, StyleRules, WithStyles, withStyles } from '@material-ui/core';

import { companyGetProjectBidById } from '../../actions/projectBidAction';
import SummaryPrintView from '../consultant/verification/SummaryPrintView';

const styles = (): StyleRules => createStyles({});

const consultantInfoDetailsView = ({ bid }: Props): ReactElement => {
  const dispatch = useDispatch();
  const [consultantInfo, setConsultantInfo] = useState();
  const fetchData = async () => {
    const data = await dispatch(companyGetProjectBidById(bid));
    setConsultantInfo(data.consultant.verification);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div>
        查看附件信息
        {consultantInfo && <SummaryPrintView consultant={consultantInfo} hideContent={false} />}
      </div>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  onClose?: Function;
  bid?: number;
}

export default withStyles(styles)(consultantInfoDetailsView);
